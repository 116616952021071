import { create } from "zustand";
import { createJSONStorage, persist, StateStorage } from "zustand/middleware";
import { del, get, set } from "idb-keyval";
import { Transcript, TranscriptEdit, Transcription } from "@/api-lib";
import { audioLanguages, LanguageCode } from "@/constants";

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

type State = {
  _hasHydrated: boolean;
  userToken?: string;
  downloadAppLaunched: boolean;
  timeLeftInHours: number;
  isFree: boolean;
  isUnlimited: boolean;
  transcripts: {
    [key: string]: Transcript;
  };
  transcriptsEdits: {
    [key: string]: TranscriptEdit["transcript"];
  };
  transcriptions: Transcription[];
  transcriptionOptions?: Pick<
    Transcription,
    "languageCode" | "withSpeakerLabels" | "numberOfSpeakers"
  >;
  deviceId?: string;
};

type Actions = {
  setUserToken: (token: string) => void;
  setHasHydrated: (status: boolean) => void;
  setTranscripts: (transcripts: State["transcripts"]) => void;
  setTimeLeftInHours: (payload: State["timeLeftInHours"]) => void;
  setIsFree: (isFree: boolean) => void;
  setIsUnlimited: (isUnlimited: boolean) => void;
  setTranscriptionOptions: (options: State["transcriptionOptions"]) => void;
  setTranscriptions: (transcriptions: State["transcriptions"]) => void;
  setTranscriptsEdits: (transcriptsEdits: State["transcriptsEdits"]) => void;
  reset: () => void;
  setDownloadAppLaunched: (downloadAppLaunched: boolean) => void;
  setDeviceId: (deviceId: string) => void;
};

// define the initial state
let defaultLanguageCode: LanguageCode = "en";
if (typeof navigator !== "undefined") {
  const language = navigator.language.split("-")[0];
  const languageOption = audioLanguages.find(
    (option) => option.value === language
  );
  if (languageOption) {
    defaultLanguageCode = languageOption.value;
  }
}
export const initialState: State = {
  _hasHydrated: false,
  transcripts: {},
  downloadAppLaunched: false,
  timeLeftInHours: 0,
  isFree: true,
  isUnlimited: false,
  transcriptsEdits: {},
  transcriptions: [],
  transcriptionOptions: {
    languageCode: defaultLanguageCode,
    withSpeakerLabels: false,
    numberOfSpeakers: undefined,
  },
};

// create store
export const useUserSlice = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setTranscripts: (transcripts) => {
        set({ transcripts });
      },
      setTranscriptsEdits: (transcriptsEdits) => {
        set({ transcriptsEdits });
      },
      setHasHydrated: (status) => {
        set({ _hasHydrated: status });
      },
      setUserToken: (token) => {
        set({ userToken: token });
      },
      setTimeLeftInHours: (payload) => {
        set({ timeLeftInHours: payload });
      },
      setIsFree: (isFree) => {
        set({ isFree });
      },
      setIsUnlimited: (isUnlimited) => {
        set({ isUnlimited });
      },
      setDownloadAppLaunched: (downloadAppLaunched) => {
        set({ downloadAppLaunched });
      },
      setTranscriptionOptions: (options) => {
        set({ transcriptionOptions: options });
      },
      setTranscriptions: (transcriptions) => {
        set({ transcriptions });
      },
      reset: () => {
        set({ ...get(), userToken: undefined });
      },
      setDeviceId: (deviceId) => {
        set({ deviceId });
      },
    }),
    {
      name: "1transcribe-user-storage",
      storage: createJSONStorage(() => storage),
      onRehydrateStorage: () => (state) => {
        state && state.setHasHydrated(true);
      },
    }
  )
);
