import { __DEV__ } from "@/constants";

const version = "3.4.6";

if (typeof window !== "undefined" && !__DEV__) {
  const isTest = window.location.href.includes("pages.dev");
  const env = isTest ? "test" : "prod";

  // Dynamically import and initialize Datadog logs
  import("@datadog/browser-logs").then(({ datadogLogs }) => {
    datadogLogs.init({
      clientToken: import.meta.env.VITE_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      service: "1transcribe-web",
      env,
      version,
      sessionSampleRate: 100,
    });
  });

  // Dynamically import and initialize Datadog RUM
  import("@datadog/browser-rum").then(({ datadogRum }) => {
    datadogRum.init({
      applicationId: import.meta.env.VITE_PUBLIC_DATADOG_RUM_APPLICATION_ID,
      clientToken: import.meta.env.VITE_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "1transcribe-rum",
      env,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
    });
  });
}
