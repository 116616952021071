import "../fetchData/datadog";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { Colors, DESKTOP_WIDTH } from "@/constants";
import { useUISlice } from "@/models/useUISlice";
import { Device } from "@capacitor/device";
import { useUserSlice } from "@/models/useUserSlice";
import { isNativeApp } from "@/models/platform";

const HelmetProvider = React.lazy(() =>
  import("react-helmet-async").then((m) => ({ default: m.HelmetProvider }))
);
const GoogleOAuthProvider = React.lazy(() =>
  import("@react-oauth/google").then((m) => ({
    default: m.GoogleOAuthProvider,
  }))
);
const Toaster = React.lazy(() =>
  import("react-hot-toast").then((m) => ({ default: m.Toaster }))
);
const AlertModal = React.lazy(() => import("@/ui/AlertModal"));
const AlertPrompt = React.lazy(() => import("@/ui/AlertPrompt"));
const AlertTranscriptOptions = React.lazy(
  () => import("@/ui/AlertTranscriptOptions")
);
const Paywall = React.lazy(() => import("@/ui/Paywall"));
const AlertDashboardStartTranscription = React.lazy(
  () => import("@/ui/AlertDashboardStartTranscription")
);
const AlertDashboardRecorder = React.lazy(
  () => import("@/ui/AlertDashboardRecorder")
);
const AlertPromptSignIn = React.lazy(() => import("@/ui/AlertPromptSignIn"));

// Lazy load analytics
const loadAnalytics = () => {
  import("@/fetchData/gtag").then((gtag) => {
    gtag.pageview(window.location.pathname);
  });
  import("@/fetchData/fpixel").then((fbq) => {
    fbq.pageview();
  });
};

export default function MyApp() {
  const [width, setWidth] = React.useState(0);
  const _hydrated = useUserSlice((state) => state._hasHydrated);
  const [loading, setLoading] = React.useState(isNativeApp());

  React.useEffect(() => {
    loadAnalytics();

    const handlePopState = () => {
      loadAnalytics();
    };

    if (isNativeApp()) {
      Device.getLanguageTag()
        .then(async (languageCode) => {
          localStorage.setItem("_lang", languageCode.value.toLowerCase());
          await import("../locales");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      import("../locales");
    }

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  React.useEffect(() => {
    setWidth(window.innerWidth);

    if (width > 0) {
      const isMobile = window.innerWidth <= DESKTOP_WIDTH - 1;
      useUISlice.getState().setIsMobile(isMobile);
      useUISlice.getState().setWidth(width);
    }
  }, [width]);

  const isHidden = loading || !width || !_hydrated;

  if (isHidden) {
    return null;
  }

  return (
    <React.Suspense>
      <HelmetProvider>
        <GoogleOAuthProvider
          clientId={import.meta.env.VITE_PUBLIC_GOOGLE_CLIENT_ID}
        >
          <Outlet />
          <AlertModal />
          <AlertPrompt />
          <AlertTranscriptOptions />
          <Paywall />
          <AlertDashboardRecorder />
          <AlertDashboardStartTranscription />
          <AlertPromptSignIn />
          <Toaster
            toastOptions={{
              style: {
                fontFamily: "sans-serif",
                color: Colors.text,
              },
            }}
          />
        </GoogleOAuthProvider>
      </HelmetProvider>
    </React.Suspense>
  );
}
